import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './Title.module.scss';

const Title = React.memo((props) => {
  return (
    <React.Fragment>
      {React.createElement(
        props.element,
        {
          className: `is-marginless title is-${props.size} ${
            props.animated ? styles.titleAnimated : ''
          } ${props.className}`,
        },
        props.text
      )}
      {props.accentBar && (
        <div
          className={`accent-bar ${
            props.animated ? styles.accentAnimated : ''
          }`}
        />
      )}
    </React.Fragment>
  );
});

Title.defaultProps = {
  animated: false,
  accentBar: false,
  element: 'h1',
  className: '',
};

Title.propTypes = {
  size: PropTypes.string.isRequired,
  animated: PropTypes.bool,
  accentBar: PropTypes.bool,
  text: PropTypes.string.isRequired,
  element: PropTypes.string,
  className: PropTypes.string,
};

export default Title;
