import { Link, StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './CaseStudyRoll.module.scss';

class CaseStudyContents extends React.PureComponent {
  render() {
    const { post } = this.props;

    this.linkRef = React.createRef();

    const followLink = () => {
      this.linkRef.current.click();
    };

    return (
      <div>
        {post.frontmatter.image != null ? (
          <GatsbyImage
            image={post.frontmatter.image.childImageSharp.gatsbyImageData}
            alt="Case Study Featured Image"
            className={`${styles.image} ${styles.link}`}
            onClick={followLink}
          />
        ) : (
          <div
            className={`${styles.image} ${styles.link}`}
            onClick={followLink}
          />
        )}
        <div className={styles.text}>
          <div
            className={`${styles.company} ${styles.link}`}
            onClick={followLink}
          >
            {post.frontmatter.company}
          </div>
          <h3 className={`${styles.title} ${styles.link}`} onClick={followLink}>
            {post.frontmatter.title}
          </h3>
          <div
            className={`${styles.description} ${styles.link}`}
            onClick={followLink}
          >
            {post.frontmatter.short_description}
          </div>
          <div className={styles.viewLinks}>
            {post.frontmatter.use_url ? (
              <a
                className={styles.viewLink}
                href={post.frontmatter.url}
                ref={this.linkRef}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>
                  <div>View project →</div>
                </div>
              </a>
            ) : (
              <Link
                to={post.fields.slug}
                className={styles.viewLink}
                ref={this.linkRef}
              >
                {' '}
                <div>
                  <div>View project →</div>
                </div>
              </Link>
            )}

            {post.frontmatter.testimonial_url ? (
              <a
                className={styles.viewLink}
                href={post.frontmatter.testimonial_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>
                  <div>View testimonial →</div>
                </div>
              </a>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export class CaseStudyRoll extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    const caseStudyClassName = [
      styles.caseStudy,
      'is-parent column is-6-big-mobile is-6',
    ].join(' ');

    const caseStudyBlockClassName = [
      styles.caseStudyBlock,
      'columns is-multiline is-big-mobile',
    ].join(' ');

    return (
      <div className={caseStudyBlockClassName}>
        {posts &&
          posts.map(({ node: post }) => (
            <div className={caseStudyClassName} key={post.id}>
              <article className={styles.caseStudyLink}>
                <CaseStudyContents post={post}></CaseStudyContents>
              </article>
            </div>
          ))}
      </div>
    );
  }
}

CaseStudyRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
};

export default () => (
  <StaticQuery
    query={graphql`
      query CaseStudyRollQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___order] }
          filter: {
            frontmatter: {
              templateKey: { eq: "case-study" }
              live: { eq: true }
            }
          }
          limit: 3
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                image {
                  childImageSharp {
                    gatsbyImageData(
                      height: 600
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                    )
                  }
                }
                templateKey
                short_description
                company
                url
                testimonial_url
                use_url
                live
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <CaseStudyRoll data={data} count={count} />}
  />
);
