// extracted by mini-css-extract-plugin
export var caseStudy = "CaseStudyRoll-module--case-study--qjIZq";
export var caseStudyBlock = "CaseStudyRoll-module--case-study-block--IfvwX";
export var caseStudyLink = "CaseStudyRoll-module--case-study-link--topNu";
export var company = "CaseStudyRoll-module--company--jR5Md";
export var description = "CaseStudyRoll-module--description--oNqYZ";
export var fadeInCaseStudy = "CaseStudyRoll-module--fade-in-case-study--OdpT0";
export var image = "CaseStudyRoll-module--image--UFGQa";
export var link = "CaseStudyRoll-module--link--9SnJM";
export var text = "CaseStudyRoll-module--text--ZaZwZ";
export var title = "CaseStudyRoll-module--title--eaRAv";
export var viewCaseStudy = "CaseStudyRoll-module--view-case-study--jvqdm";
export var viewLink = "CaseStudyRoll-module--view-link--uSBaW";
export var viewLinks = "CaseStudyRoll-module--view-links--qRdNg";