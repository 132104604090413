import React from 'react';

import FullCaseStudyRoll from '../../components/CaseStudyRollFull';
import Layout from '../../components/Layout';
import Title from '../../components/Title';
import * as styles from './CaseStudiesPage.module.scss';

class CaseStudyIndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.popDownNavBar = React.createRef();
  }

  render() {
    return (
      <Layout backdropThree>
        <section className={`section after-nav ${styles.section}`}>
          <div className="lab3-container">
            <div>
              <div
                className="full-width-image-container margin-top-0"
                style={{}}
              >
                <Title accentBar text="Our Work" size="1" animated />
              </div>
            </div>
            <FullCaseStudyRoll />
          </div>
        </section>
      </Layout>
    );
  }
}

export default CaseStudyIndexPage;
