import { StaticQuery, graphql } from 'gatsby';
import React from 'react';

import { CaseStudyRoll } from './CaseStudyRoll';

export default () => (
  <StaticQuery
    query={graphql`
      query FullCaseStudyRollQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___order] }
          filter: {
            frontmatter: {
              templateKey: { eq: "case-study" }
              live: { eq: true }
            }
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                image {
                  childImageSharp {
                    gatsbyImageData(
                      height: 600
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                    )
                  }
                }
                templateKey
                short_description
                company
                url
                testimonial_url
                use_url
                live
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <CaseStudyRoll data={data} count={count} />}
  />
);
